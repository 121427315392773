import request from '@/utils/request'
// 查询接口列表
export function getqueidxs(params) {
    params.dataType='noKey';
    return request({
        url: '/ques/queidx',
        method: 'post',
        params:params
      })
  }


  // 查询问卷结构
export function getquestus(params) {
    params.dataType='noKey';
    return request({
        url: '/ques/que',
        method: 'post',
        params:params
      })
  }


  // 查询问卷数据
export function getqueDatas(params) {
    params.dataType='noKey';
    return request({
        url: '/ques/datas',
        method: 'post',
        params:params
      })
  }

  export function saveDatas(params,data){
    params.dataType='noKey';
    return request({
        url: '/ques/saveDatas',
        method: 'post',
        params:params,
        data:data
      })
  }


  
  export function pushques(params) {
    params.dataType='noKey';
    return request({
        url: '/ques/push',
        method: 'post',
        params:params
      })
  }