<template>
<div>
  <BaseInfo :baseInfo="$store.state.user.baseInfo" />
<NavBar
  title="问卷列表"
  left-arrow
  @click-left="$router.go(-1)"
/>
  <cell v-for="(idx,index) in quesidxs" :title="idx.questionnaireName" :key="index"  
   is-link @click="showQue(idx)"  >
   <template #label  >
    <div class="create_time" v-if="!idx.uptime" >{{parseTime(idx.createTime)}}</div>
    <div class="is_completed" v-if="idx.uptime">{{parseTime(idx.uptime)}}</div>
  </template>
   <template   >
    <span class="is_completed" v-if="idx.completed==1" >已完成</span>
    <span class="is_uncompleted" v-if="idx.completed==0" >未完成</span>
  </template>
  </cell>
 <RLFooter/>
</div>
</template>

<script>

import {Cell} from 'vant';
import {getqueidxs} from '@/api/ques';

export default {
  name: 'Followcrfs',
  components: {
    Cell
  },
  data(){
    return {
      quesidxs:[],
    };
  },
  computed:{
    
  },
  methods:{
      initidx(){
        console.info(this.$store.state.user);
        //this.$store.commit('updatePid',this.$route.params.pid);
        getqueidxs({pid:this.$route.params.pid}).then((response) => {
           this.loading=true;
          if(this.quesidxs.length==0){
              this.quesidxs=response.data;
          }
        });
      },
      showQue(idx){
         this.$router.push({path: '/que/'+idx.id});
      },
      formaterState(fins){
        if(fins==1){
           return '未完成';
        }else{
           return '已完成';
        }
      },
      formaterTime(ctime,uptime){
        let res="";
        if(ctime){
          res+="创建时间："+this.parseTime(ctime);
        }
        if(uptime){
          res+="    更新时间："+this.parseTime(uptime);
        }
        return res;
      }
  }
,
   created() {
    /*初始化列表*/
   this.initidx();
    
  },
}
</script>

<style scoped>

.is_completed{
  color: aqua;
}
.is_uncompleted{
  color:red;
}
  
</style>
